import React from 'react';

// Main App Component
function App() {
  // Data for the stories
  const stories = [
    {
      id: 1,
      title: "Protecting Your Small Business: 9 Common Payment Process Vulnerabilities and How to Prevent Them",
      link: "https://medium.com/@hij4ck/protecting-your-small-business-9-common-payment-process-vulnerabilities-and-how-to-prevent-them-a50bde2ff3f2",
      image: "https://miro.medium.com/v2/resize:fit:1100/format:webp/0*QtS-ZJPCwq6nqN5f.png", 
      description: "Discover the most common payment processing vulnerabilities that small businesses face and learn effective strategies to safeguard your transactions.",
    },
    {
      id: 2,
      title: "How Small Businesses Can Leverage AI to Stay Competitive in 2025",
      link: "https://medium.com/p/ee2abe7ae49a",
      image: "https://miro.medium.com/v2/resize:fit:1100/format:webp/0*hVGQ3LFb9xRz_bHO", 
      description: "Artificial intelligence (AI) isn’t just for tech giants and Silicon Valley startups anymore.",
    },
    {
      id: 3,
      title: "How Cybersecurity Threats Are Evolving in 2025: What Small Businesses Need to Know",
      link: "https://medium.com/p/c3da08e4d2c9",
      image: "https://miro.medium.com/v2/resize:fit:1100/format:webp/0*iegdjx9TTtJ7uhnG", 
      description: "In 2013, a sophisticated cyberattack on Target made headlines worldwide, exposing the credit card information of over 40 million customers. The shocking twist? Hackers didn’t break through the company’s primary IT infrastructure.",
    },
  ];

  return (
    <div style={styles.app}>
      {/* Hero Section */}
      <header style={styles.hero}>
        <h1 style={styles.title}>HIJ4CK</h1>
        <a href="mailto:info@hij4ck.com" style={styles.emailLink}>
          info@hij4ck.com
        </a>
      </header>

      {/* About Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>What We Do</h2>
        <p style={styles.sectionText}>
          At HIJ4CK, we specialize in cutting-edge cybersecurity and AI-driven solutions that protect, innovate, and optimize. Whether safeguarding your data or building intelligent systems, we empower businesses to thrive in a fast-paced digital world.
        </p>
      </section>

      {/* Gallery Section */}
      <section style={styles.gallerySection}>
        <h2 style={styles.sectionTitle}>Our Stories</h2>
        <div style={styles.gallery}>
          {stories.map((story) => (
            <a href={story.link} target="_blank" rel="noopener noreferrer" key={story.id} style={styles.cardLink}>
              <div style={styles.card}>
                <div style={styles.imageContainer}>
                  <img src={story.image} alt={story.title} style={styles.image} />
                </div>
                <div style={styles.cardContent}>
                  <h3 style={styles.cardTitle}>{story.title}</h3>
                  <p style={styles.cardDescription}>{story.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section>

      {/* Footer */}
      <footer style={styles.footer}>
        <p style={styles.footerText}>&copy; {new Date().getFullYear()} Hij4ck. All rights reserved.</p>
      </footer>
    </div>
  );
}

// Inline Styles Object
const styles = {
  app: {
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    color: '#000000',
    backgroundColor: '#FFFFFF',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  hero: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    padding: '100px 20px',
    textAlign: 'center',
  },
  title: {
    fontSize: '3rem',
    marginBottom: '20px',
    lineHeight: '1.2',
  },
  emailLink: {
    fontSize: '1rem',
    color: '#FFFFFF',
    textDecoration: 'none',
    border: '2px solid #FFFFFF',
    padding: '10px 20px',
    borderRadius: '25px',
    transition: 'background-color 0.3s, color 0.3s',
  },
  section: {
    padding: '60px 20px',
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#000000',
  },
  sectionText: {
    fontSize: '1rem',
    color: '#333333',
    lineHeight: '1.6',
  },
  gallerySection: {
    padding: '60px 20px',
    backgroundColor: '#f9f9f9',
  },
  gallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  cardLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'transform 0.3s, box-shadow 0.3s',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  imageContainer: {
    width: '100%',
    paddingTop: '56.25%', // 16:9 Aspect Ratio
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'grayscale(100%)',
    transition: 'filter 0.3s',
  },
  cardContent: {
    padding: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontSize: '1.25rem',
    marginBottom: '10px',
    color: '#000000',
  },
  cardDescription: {
    fontSize: '0.95rem',
    color: '#666666',
    flexGrow: 1,
  },
  footer: {
    backgroundColor: '#000000',
    padding: '20px',
    textAlign: 'center',
    marginTop: 'auto',
  },
  footerText: {
    fontSize: '0.9rem',
    color: '#FFFFFF',
    margin: 0,
  },
};

export default App;
